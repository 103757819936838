.c-team {
  margin: 0;
  list-style-type: none;

  @include bp-large {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-team__card {
  position: relative;
  display: block;
  margin: 0;
  text-align: center;
  cursor: pointer;

  @include bp-large {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.3%;
    margin-top: 5rem;
    text-align: left;
  }

  & + & {
    margin-top: 2.5rem;

    @include bp-large {
      margin-top: 5rem;
    }
  }
}

.c-team__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.4rem;
  height: 16.4rem;
  border-radius: 99rem;
  margin: 0 auto;
  background-color: white;

  @include bp-large {
    width: 14rem;
    height: 14rem;
  }

  @include bp-full {
    width: 18.6rem;
    height: 18.6rem;
  }
}

.c-team__info {
  display: inline-block;

  @include bp-large {
    width: 14rem - 1rem;
  }

  @include bp-full {
    width: 18.6rem - 1rem;
  }
}

.c-team__image {
  max-width: 95%;
  max-height: 95%;
  margin-top: 2rem;
}

.c-team__name {
  position: relative;
  z-index: 2;
  margin-top: 1.2rem;
  color: $color-primary;
  font-size: 1.8rem;
  font-weight: 500;
  display: inline-block;

  @include bp-large {
    font-size: 2rem;
    white-space: nowrap;
  }

  @include bp-full {
    font-size: 2.4rem;
  }

  .theme-purple & {
    color: $color-purple;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 28%;
    bottom: 28%;
    background: $color-secondary;
    opacity: 0;
    transition: opacity $global-transition-time;
  }

  > * {
    position: relative;
    z-index: 1;
  }
}

.c-team__familyname:after {
  content: ",";
}

.c-team__familyname--no-degree:after {
  content: "";
}

.c-team__card:hover .c-team__name {
  &:before {
    opacity: 1;
  }
}

.c-team__role {
  color: $color-primary;
  font-size: 1.6rem;
  font-weight: normal;

  @include bp-large {
    font-size: 1.8rem;
    white-space: nowrap;
  }

  @include bp-full {
    font-size: 2rem;
  }

  .theme-purple & {
    color: $color-purple;
  }
}

.c-team__org {
  font-size: 1.4rem;
  color: #75747a;

  @include bp-large {
    font-size: 1.6rem;
    white-space: nowrap;
  }

  @include bp-full {
    font-size: 1.8rem;
  }
}

.c-team__details {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.25s;
  transform: scale(0);
  transform-origin: center 25%;
  pointer-events: none;
  cursor: default;

  @media (max-width: $bp-large - 1) {
    top: 0 !important;
    left: 0 !important;
  }

  @include bp-large {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: $wrapper-width-mid - 160px;
    height: auto;
    box-shadow: 2px 2px 12px rgba($color-black, 0.2);
  }

  @include bp-full {
    width: $wrapper-width-big - 160px;
  }
}

.has-modal-opened .c-team__details {
  transform: scale(1);
  pointer-events: all;

  @include bp-large {
    display: block;
    transform: translateX(-50%) translateY(-50%);
  }
}

.c-team__details-header {
  padding: $wrapper-padding;
  color: $color-white;
  background-color: $color-primary;

  @include bp-large {
    padding: 3rem 8rem;
  }

  .theme-purple & {
    background-color: $color-purple;
  }
}

.c-team__details-name {
  color: inherit;
  margin: 0;
  font-size: 1.8rem;

  .theme-purple & {
    color: $color-white;
  }

  @include bp-large {
    font-size: 4rem;
  }
}

.c-team__details-role {
  font-size: 1.6rem;

  @include bp-large {
    font-size: 2.8rem;
    font-style: italic;
  }
}

.c-team__details-org {
  font-size: 1.4rem;

  @include bp-large {
    display: none;
  }
}

.c-team__details-content {
  flex-grow: 1;
  background-color: $color-white;

  @include bp-large {
    padding: 2rem 8rem;
  }
}

.c-team__details-note {
  margin: $wrapper-padding;
  text-align: left;

  @include bp-large {
    margin: $wrapper-padding 0;
  }
}
