/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  color: $color-purple;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
}


blockquote p {
  font-size: 2rem;

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  @include bp-medium {
    max-width: 65.6rem;
    margin-left: auto;
    margin-right: auto;
  }

  @include bp-large {
    font-size: 2.5rem;
  }
}

blockquote footer {
  margin-top: 2rem;

  @include bp-medium {
    text-align: center;
    margin-top: 3rem;
  }

  @include bp-large {
    font-size: 2rem;
    margin-top: 4rem;
  }
}

blockquote footer span {
  display: block;
}
