.c-legal-content {
  em {
    font-style: italic;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 2em;

    ol,
    ul {
      margin: 1.5em 0;
    }
  }

  li {
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: 0.21px;
    margin-bottom: 0;

    @include bp-full {
      font-size: 1.9rem;
    }

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  strong {
    font-weight: 600;
  }

  a {
    color: $color-purple-v2;
    font-weight: 600;
    text-decoration: none;
  }
}

.c-legal-content__contact {
  padding-top: 1em;
  padding-left: 2em;

  @include bp-large {
    padding-top: 3em;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
