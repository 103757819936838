.c-pipeline {
  --title-column-width: 213px;

  margin-top: 60px;

  @include bp-large {
    margin-top: 114px;
    margin-inline: -80px;
  }

  @include bp-full {
    margin-inline: 0;
  }
}

.c-pipeline__content {
  margin-inline: -20px;

  @include bp-large {
    margin-inline: 0;
  }
}

.c-pipeline__header {
  display: none;
  justify-content: space-between;
  padding-left: var(--title-column-width);
  padding-bottom: 20px;

  @include bp-large {
    display: flex;
  }
}

.c-pipeline__header-title {
  font-size: 2.1rem;
  font-weight: 500;
  color: $color-purple;

  @include bp-xlarge {
    font-size: 2.4rem;
  }
}

.c-pipeline__row {
  display: grid;
  column-gap: 30px;
  padding-block: 3.7rem 4rem;
  padding-inline: 2rem;
  background: var(
    --gradient,
    linear-gradient(270deg, #dadeea 0%, rgba(217, 217, 217, 0) 100%)
  );

  & + & {
    border-top: 0.5px solid $color-medium-grey;
  }

  sup {
    top: -0.7em;
    font-size: 60%;
  }

  @include bp-large {
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: var(--title-column-width) auto 1fr;
    gap: 0;
    padding-inline: 0;
  }
}

.c-pipeline__row-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  grid-column: 1/-1;

  @include bp-large {
    display: block;
    grid-column: unset;
    margin: 0;
  }
}

/* stylelint-disable selector-no-qualifying-type */
h3.c-pipeline__row-title {
  margin: 0;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: $color-medium-grey;

  b {
    display: block;
    font-size: 2.2rem;
    font-weight: 500;
    color: $color-purple-v2;
  }
}

/* stylelint-enable selector-no-qualifying-type */

.c-pipeline__row-toggle {
  display: flex;
  align-items: center;
  gap: 9px;
  border: 0;
  padding: 0;
  font-size: 1.9rem;
  font-family: inherit;
  font-weight: 500;
  color: $color-purple-v2;
  background: $color-secondary;
  cursor: pointer;

  .is-open {
    display: none;
  }

  .has-pipeline-opened & {
    .is-closed,
    .vert {
      display: none;
    }

    .is-open {
      display: block;
    }
  }

  @include bp-large {
    margin-top: 2rem;
  }
}

.c-pipeline__row-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  grid-column: 1/-1;

  @include bp-large {
    display: none;
  }
}

.c-pipeline__row-header-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: $color-purple;

  @include bp-small {
    flex: 1;
  }
}

.c-pipeline__row-arrow {
  display: grid;
  align-items: center;
  grid-column: 1;
  grid-row: 3;

  &::before {
    content: "";
    display: block;
    height: 30px;
    width: 18%;
    background: url("../assets/images/box-arrow.svg") center right no-repeat;
    background-size: cover;
  }

  @include bp-small {
    &::before {
      width: 25%;
    }
  }

  @include bp-large {
    position: relative;
    z-index: 1;
    min-height: 80px;
    min-width: 164px;
    padding: 1.6rem 2.4rem 1.6rem 1.6rem;
    grid-column: unset;
    grid-row: unset;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }

  @include bp-xlarge {
    min-width: 150px;
  }
}

.c-pipeline__row-arrow--wide {
  &:before {
    width: 38%;
  }

  @include bp-small {
    &::before {
      width: 36%;
    }
  }

  @include bp-large {
    min-width: 270px;

    &::before {
      width: 100%;
    }
  }
}

.c-pipeline__row-arrow-title {
  font-size: 1.9rem;
  line-height: 1.26;
  margin-top: 8px;

  br {
    display: none;
  }

  @include bp-large {
    margin: 0;
    color: $color-white;

    br {
      display: block;
    }
  }
}

.c-pipeline__row-note {
  grid-column: 1;
  grid-row: 3;
  font-style: italic;
  padding-left: 2.6rem;
  margin-top: 3px;
  margin-left: 18%;

  @include bp-small {
    margin-left: 25%;
  }

  @include bp-large {
    position: static;
    grid-row: unset;
    grid-column: unset;
    margin-left: 0;
    margin-top: 0;
  }
}

.c-pipeline__row-more {
  display: none;
  grid-row: 2;
  grid-column: 1/-1;
  padding-bottom: 2.9rem;

  .has-pipeline-opened & {
    display: block;
  }

  @include bp-large {
    padding-top: 3rem;
    padding-bottom: 0;
    grid-row: 2/3;
    grid-column: 1/4;
    padding-right: 10.2rem;
  }
}
