.o-picture {
  display: block;
  padding: 5rem 0;
  background-color: #f0f0f0;

  @include bp-medium {
    padding: 5rem;
  }
}

.o-picture__image {
  display: block;
  margin: auto;
  max-width: 100%;
}

.o-picture__image--mobile-restricted-width {
  max-width: 20.4rem;

  @include bp-medium {
    max-width: none;
  }
}
