.u-veryshort {
  max-width: 28rem;
}

.u-veryshort--large {
  @include bp-large {
    max-width: 28rem;
  }
}

.u-veryshort--full {
  @include bp-large {
    max-width: 37rem;
  }
}

.u-short--large {
  @include bp-large {
    max-width: 45rem;
  }
}

.u-short--xlarge {
  @include bp-large {
    max-width: 65rem;
  }
}

.u-short--full {
  @include bp-full {
    max-width: 56rem;
  }
}

.u-short--xfull {
  @include bp-full {
    max-width: 78rem;
  }
}

.u-short--indent {
  padding-left: 8rem;
}
