/*
  Project: Fuzionaire
  Author: Xfive
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* stylelint-disable value-list-comma-space-after */
@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-Light.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-Regular.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-RegularItalic.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-RegularItalic.woff") format("woff");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-Medium.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-MediumItalic.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-MediumItalic.woff") format("woff");
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-Semibold.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-Semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: calibreweb;
  font-display: swap;
  src: url("../assets/fonts/CalibreWeb-Bold.woff2") format("woff2"), url("../assets/fonts/CalibreWeb-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

[width] {
  width: auto;
}

[height] {
  height: auto;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  color: #614ab8;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: italic;
  line-height: 1.25;
  text-align: center;
}

blockquote p {
  font-size: 2rem;
}

blockquote p:before {
  content: open-quote;
}

blockquote p:after {
  content: close-quote;
}

@media (min-width: 768px) {
  blockquote p {
    max-width: 65.6rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  blockquote p {
    font-size: 2.5rem;
  }
}

blockquote footer {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  blockquote footer {
    text-align: center;
    margin-top: 3rem;
  }
}

@media (min-width: 1024px) {
  blockquote footer {
    font-size: 2rem;
    margin-top: 4rem;
  }
}

blockquote footer span {
  display: block;
}

/* ==========================================================================
   #EM element
   ========================================================================== */
em {
  font-style: normal;
}

figure {
  margin: 0;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
h1 {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  color: #fff;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.72px;
}

h1 [data-highlight] {
  font-size: 0;
  padding: 1.4rem 0 0;
}

h1 [data-highlight]:before {
  font-size: 3rem;
}

@media (min-width: 1024px) {
  h1 {
    margin-top: 10rem;
    max-width: 796px;
    padding-left: 0;
    padding-right: 0;
    font-size: 4.5rem;
  }
  h1 [data-highlight] {
    font-size: 0;
    padding: 2.1rem 0 0;
  }
  h1 [data-highlight]:before {
    font-size: 4.5rem;
  }
}

@media (min-width: 1440px) {
  h1 {
    margin-top: 15rem;
    max-width: 956px;
    font-size: 6rem;
  }
  h1 [data-highlight] {
    font-size: 0;
    padding: 2.8rem 0 0;
  }
  h1 [data-highlight]:before {
    font-size: 6rem;
  }
}

h2 {
  margin: 3rem 0;
  color: #0e3da4;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
}

h2 [data-highlight] {
  font-size: 0;
  padding: 1.2rem 0 0;
}

h2 [data-highlight]:before {
  font-size: 2.4rem;
}

@media (min-width: 768px) {
  h2 {
    font-size: 3.2rem;
    letter-spacing: 0.2px;
  }
  h2 [data-highlight] {
    font-size: 0;
    padding: 1.5rem 0 0;
  }
  h2 [data-highlight]:before {
    font-size: 3.2rem;
  }
}

@media (min-width: 1024px) {
  h2 {
    margin: 4rem 0 3rem;
  }
}

@media (min-width: 1440px) {
  h2 {
    font-size: 4rem;
    letter-spacing: 0.25px;
  }
  h2 [data-highlight] {
    font-size: 0;
    padding: 1.9rem 0 0;
  }
  h2 [data-highlight]:before {
    font-size: 4rem;
  }
}

h3 {
  margin: 2rem 0;
  color: #0e3da4;
  font-size: 1.8rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 0.24px;
}

@media (min-width: 1024px) {
  h3 {
    margin: 3rem 0 2rem;
  }
}

@media (min-width: 1440px) {
  h3 {
    margin: 4rem 0 2rem;
    font-size: 2.4rem;
    letter-spacing: 0.32px;
  }
}

h3.has-even-vmargin {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  h3.has-even-vmargin {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

@media (min-width: 1440px) {
  h3.has-even-vmargin {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

h4 {
  margin: 0;
  color: #0e3da4;
  font-size: 1.9rem;
  font-weight: 500;
}

/* stylelint-disable selector-no-qualifying-type */
.theme-purple h2, .theme-purple
h3, .theme-purple
h4 {
  color: #614ab8;
}

/* stylelint-enable selector-no-qualifying-type */
/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
html {
  min-height: 100%;
  color: #68637b;
  font-family: calibreweb, calibre, helvetica, arial, sans-serif;
  font-size: 0.625rem;
  line-height: 1.5;
  background: #fff;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

html.has-modal-active {
  overflow-y: hidden;
}

body {
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
}

.has-modal-active body {
  overflow-y: hidden;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
  color: transparent;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #68637b;
  text-decoration: underline;
}

a:hover {
  color: #6bf0fc;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

main {
  margin-top: 5rem;
  flex-grow: 1;
}

@media (min-width: 1024px) {
  main {
    margin-top: 0;
  }
}

p {
  margin: 2rem 0;
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.21px;
}

@media (min-width: 1440px) {
  p {
    font-size: 1.9rem;
  }
}

section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (min-width: 1024px) {
  section {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}

@media (min-width: 1440px) {
  section {
    margin-top: 14.5rem;
    margin-bottom: 14.5rem;
  }
}

.theme-purple section {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

@media (min-width: 1024px) {
  .theme-purple section {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
}

@media (min-width: 1440px) {
  .theme-purple section {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
  text-align: left;
  font-size: 0.85rem;
  line-height: 1;
}

@media (min-width: 480px) {
  table {
    font-size: 1.3rem;
  }
}

@media (min-width: 1440px) {
  table {
    font-size: 1.7rem;
  }
}

th, td {
  padding: 0.7rem 1.5rem;
  border: solid #f0f0f0;
  border-width: 0 0 0.1rem 0.05rem;
}

th:first-child, td:first-child {
  border-left-width: 0;
}

@media (min-width: 480px) {
  th, td {
    padding: 1.4rem 1.9rem;
    border-width: 0 0 0.2rem 0.7rem;
  }
  th:first-child, td:first-child {
    border-left-width: 0;
  }
}

@media (min-width: 1440px) {
  th, td {
    padding: 1.7rem 2.6rem;
  }
}

th {
  font-weight: 600;
  color: #0e3da4;
  background: #f0f0f0;
  border-bottom-color: white;
}

.theme-purple th {
  color: #614ab8;
}

td {
  background: white;
}

thead th {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: #0f3b9a;
  border-bottom-width: 0;
}

@media (min-width: 480px) {
  thead th {
    font-size: 1.5rem;
    border-bottom-width: 0;
  }
}

@media (min-width: 1440px) {
  thead th {
    font-size: 2rem;
  }
}

.theme-purple thead th {
  color: white;
  background: #4b3896;
}

.o-bg {
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
  background-color: #f0f0f0;
}

@media (min-width: 1024px) {
  .o-bg--left {
    padding-left: calc(50vw - 478px);
    margin-right: calc(50vw - 478px);
  }
}

@media (min-width: 1440px) {
  .o-bg--left {
    padding-left: calc(50vw - 638px);
    margin-right: calc(50vw - 638px);
  }
}

@media (min-width: 1024px) {
  .o-bg--right {
    padding-right: calc(50vw - 478px);
    margin-left: calc(50vw - 478px);
  }
}

@media (min-width: 1440px) {
  .o-bg--right {
    padding-right: calc(50vw - 638px);
    margin-left: calc(50vw - 638px);
  }
}

.o-bg--primary {
  background-color: #0e3da4;
}

.theme-purple .o-bg--primary {
  background-color: #4b3896;
}

.o-bg--periodic {
  min-height: 13.3rem;
}

@media (min-width: 1024px) {
  .o-bg--periodic {
    min-height: 8.4rem;
  }
}

@media (min-width: 1440px) {
  .o-bg--periodic {
    min-height: 35.2rem;
    background: url("../assets/images/bg-periodic.jpg") center center no-repeat;
    background-size: cover;
  }
}

.o-bg--bubbles {
  min-height: 32.4rem;
  background: url("../assets/images/diagnostics/bg-bubbles2.jpg") center center no-repeat;
  background-size: cover;
}

.o-bg--pet {
  min-height: 27.7rem;
  background: url("../assets/images/diagnostics/bg-pet.jpg") center center no-repeat;
  background-size: cover;
}

@media (min-width: 1024px) {
  .o-bg--pet {
    min-height: 32.4rem;
  }
}

@media (min-width: 1440px) {
  .o-bg--pet {
    min-height: 42.8rem;
  }
}

.o-bg--about {
  min-height: 12rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .o-bg--about {
    max-width: 956px;
  }
}

@media (min-width: 1440px) {
  .o-bg--about {
    max-width: 1276px;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.o-btn {
  display: inline-block;
  vertical-align: middle;
  padding: 0.9rem 2rem 1.1rem;
  border: 1px solid #68637b;
  border-radius: 23px;
  margin: 3rem 0;
  font-family: inherit;
  font-size: 1.9rem;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  transition: all 0.25s;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .o-btn {
    padding: 1rem 3rem;
    margin: 3rem 0;
    font-size: 2.1rem;
  }
}

@media (min-width: 1440px) {
  .o-btn {
    margin: 4rem 0;
  }
}

.o-btn:hover, .o-btn:focus {
  color: #fff;
  background-color: #68637b;
}

.o-btn--center {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .o-btn--left-on-large {
    margin-left: 0;
  }
}

.o-btn--collapse {
  display: table;
}

.o-btn--primary {
  color: #0e3da4;
  border-color: #0e3da4;
}

.o-btn--primary:hover, .o-btn--primary:focus {
  color: #fff;
  background-color: #0e3da4;
}

.theme-purple .o-btn--primary {
  font-weight: 300;
  color: #614ab8;
  border-color: #614ab8;
}

.theme-purple .o-btn--primary:hover, .theme-purple .o-btn--primary:focus {
  color: #fff;
  background-color: #614ab8;
}

.o-btn--secondary {
  color: #6bf0fc;
  border-color: #6bf0fc;
}

.o-btn--secondary:hover, .o-btn--secondary:focus {
  color: #fff;
  background-color: #6bf0fc;
}

.o-btn--small {
  line-height: 1;
  padding: 0.6rem 2rem 0.7rem;
  border-width: 1px;
  font-size: 2.16rem;
}

.o-btn--outline {
  color: #fff;
  border-color: #fff;
}

.o-btn--outline:hover, .o-btn--outline:focus {
  color: #0e3da4;
  background-color: #fff;
}

.theme-purple .o-btn--outline:hover, .theme-purple .o-btn--outline:focus {
  color: #614ab8;
}

.o-connector {
  position: relative;
  z-index: 1;
  padding-bottom: 8rem;
}

@media (min-width: 1024px) {
  .o-connector {
    padding-bottom: 8rem;
  }
}

.o-connector::before, .o-connector::after {
  position: absolute;
  content: '';
  display: block;
}

.o-connector::before {
  left: 50%;
  bottom: 0;
  width: 1px;
  height: 14rem;
  background: #614ab8;
  transform: translateY(50%);
}

.o-connector::after {
  left: 50%;
  bottom: 7rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid #614ab8;
  margin: 0;
  border-radius: 50%;
  transform: translateX(-0.95rem);
}

@media (min-width: 1024px) {
  .o-connector.o-bg--left::before, .o-connector.o-bg--left::after {
    right: 478px;
  }
}

@media (min-width: 1440px) {
  .o-connector.o-bg--left::before, .o-connector.o-bg--left::after {
    right: 638px;
  }
}

@media (min-width: 1024px) {
  .o-connector.o-bg--right::before, .o-connector.o-bg--right::after {
    left: 478px;
  }
}

@media (min-width: 1440px) {
  .o-connector.o-bg--right::before, .o-connector.o-bg--right::after {
    left: 638px;
  }
}

@media (min-width: 1024px) {
  left: 478px;
}

@media (min-width: 1440px) {
  left: 638px;
}

.o-connector + * {
  padding-top: 5rem;
}

@media (min-width: 1024px) {
  .o-connector + * {
    padding-top: 9rem;
  }
}

.o-connector--shifted-up::before {
  transform: translateY(5rem);
}

.o-connector--shifted-up::after {
  transform: translateY(-2rem) translateX(-0.95rem);
}

.o-connector--flipped::before {
  bottom: 1rem;
}

.o-connector--flipped::after {
  bottom: -8rem;
}

.o-connector--line::before {
  height: 16rem;
}

@media (min-width: 1024px) {
  .o-connector--line::before {
    left: calc(478px);
  }
}

@media (min-width: 1440px) {
  .o-connector--line::before {
    left: calc(638px);
  }
}

.o-connector--line::after {
  display: none;
}

.o-connector--secondary::before {
  background: #6bf0fc !important;
}

.o-connector--secondary::after {
  border-color: #6bf0fc !important;
}

.o-figure {
  display: block;
  margin: 5rem auto;
}

.o-figure[width], .o-figure[height] {
  max-width: 24rem;
}

.o-figure.is-small {
  max-width: 16rem;
}

@media (min-width: 768px) {
  .o-figure {
    margin: 0 auto;
  }
}

.o-highlight, .o-highlight--primary, .o-highlight--secondary {
  position: relative;
  letter-spacing: 0;
  background-color: #68637b;
}

.o-highlight[data-highlight]:before, .o-highlight--primary[data-highlight]:before, .o-highlight--secondary[data-highlight]:before {
  content: attr(data-highlight);
}

.o-highlight--primary {
  background-color: #0f3b9a;
}

.theme-purple .o-highlight--primary {
  background-color: #4b3896;
}

.o-highlight--secondary {
  background-color: #6bf0fc;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

.o-layout--v-centered {
  align-items: center;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--5 > .o-layout__item {
    width: 24%;
  }
}

.o-link {
  display: block;
  text-decoration: none;
}

.o-link__text {
  position: relative;
  display: inline-block;
  color: #0e3da4;
  font-size: 1.8rem;
  text-decoration: none;
}

.o-link__text:after {
  content: '';
  display: block;
  width: 100%;
  height: 0.9rem;
  margin-top: 1rem;
  background: #6bf0fc;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin: 0;
}

@media (min-width: 1024px) {
  .o-list-bare {
    margin: 0 0 0 8rem;
  }
}

.o-list-bare__item {
  line-height: 1.25;
  padding: 0.8rem 0;
}

@media (min-width: 1024px) {
  .o-list-bare__item {
    letter-spacing: 0.25px;
  }
}

@media (min-width: 1440px) {
  .o-list-bare__item {
    font-size: 1.9rem;
  }
}

.o-list-bare__link {
  text-decoration: none;
}

.o-list-bare__link span {
  display: block;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-picture {
  display: block;
  padding: 5rem 0;
  background-color: #f0f0f0;
}

@media (min-width: 768px) {
  .o-picture {
    padding: 5rem;
  }
}

.o-picture__image {
  display: block;
  margin: auto;
  max-width: 100%;
}

.o-picture__image--mobile-restricted-width {
  max-width: 20.4rem;
}

@media (min-width: 768px) {
  .o-picture__image--mobile-restricted-width {
    max-width: none;
  }
}

.o-relative {
  padding-top: 1px;
  padding-bottom: 1px;
  position: relative;
}

.o-separator {
  display: block;
  margin: 0 2rem;
  height: 0.2rem;
  max-width: 82.5rem;
  background: #f0f0f0;
  border: 0;
}

@media (min-width: 1024px) {
  .o-separator {
    margin: 0 auto;
    height: 0.4rem;
  }
}

.o-bg .o-separator {
  background: white;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 956px) {
  .o-wrapper {
    max-width: 956px;
    padding-left: calc(20px - (50vw - 478px));
    padding-right: calc(20px - (50vw - 478px));
  }
}

@media (min-width: 996px) {
  .o-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .o-wrapper {
    max-width: 796px;
  }
}

@media (min-width: 1440px) {
  .o-wrapper {
    max-width: 956px;
  }
}

.o-wrapper--static {
  position: static;
}

.c-404 {
  position: relative;
  max-width: 956px;
  padding: 1px 2rem;
  margin: auto !important;
  background-color: #f0f0f0;
}

@media (min-width: 1440px) {
  .c-404 {
    max-width: 1276px;
    min-height: 60rem;
    padding-left: 160px;
    padding-right: 160px;
  }
}

.c-404__heading {
  margin-top: 5rem;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1024px) {
  .c-404__heading {
    margin-top: 15rem;
  }
}

.c-404__subheading {
  font-size: 1.6rem;
}

@media (min-width: 1024px) {
  .c-404__subheading {
    font-size: 2.4rem;
    max-width: 55%;
  }
}

.c-article__banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.c-article__banner--regular {
  height: 23.3rem;
  background-image: url("../assets/images/bg-verticals-materials.jpg");
  background-position-x: 75%;
}

@media (min-width: 1024px) {
  .c-article__banner--regular {
    height: 35rem;
  }
}

@media (min-width: 1440px) {
  .c-article__banner--regular {
    height: 46.5rem;
  }
}

.c-article__banner--tall {
  height: 33.8rem;
  background-image: url("../assets/images/bg-verticals-energy.jpg");
  background-position-y: bottom;
}

@media (min-width: 1440px) {
  .c-article__banner--tall {
    height: 45rem;
  }
}

.c-branches__container {
  text-align: center;
}

@media (min-width: 1024px) {
  .c-branches__container {
    text-align: left;
  }
}

.c-branches__image {
  display: block;
  max-width: 17rem;
  margin: 3rem auto;
}

@media (min-width: 1024px) {
  .c-branches__image {
    position: absolute;
    right: -5rem;
    top: -6rem;
    max-width: 20rem;
    margin: 0;
  }
}

@media (min-width: 1440px) {
  .c-branches__image {
    position: absolute;
    top: -7rem;
    max-width: 24rem;
  }
}

.c-brands {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 480px) {
  .c-brands {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: none;
    margin: 0;
  }
}

.c-brands__logo {
  margin: 2rem;
  align-self: center;
}

@media (min-width: 480px) {
  .c-brands__logo {
    margin: 3rem;
  }
}

@media (min-width: 768px) {
  .c-brands__logo {
    margin: 4rem;
  }
}

@media (min-width: 1024px) {
  .c-brands__logo {
    margin: 5rem;
  }
}

@media (min-width: 1440px) {
  .c-brands__logo {
    margin: 6rem;
  }
}

.c-brands__logo--nature {
  width: 10rem;
  opacity: 0.4;
}

.c-brands__logo--nature-energy {
  width: 10rem;
  opacity: 0.45;
}

.c-brands__logo--caltech {
  width: 10rem;
  opacity: 0.35;
}

.c-challenges__heading {
  text-align: center;
}

.c-challenges * {
  background-color: inherit;
}

.c-closing-x {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  padding: 2rem;
}

.c-closing-x__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.8rem;
  height: 2rem;
  transform: translateZ(1px);
  cursor: pointer;
}

.c-closing-x__bar {
  display: block;
  width: 100%;
  height: 2px;
  transform-origin: center center;
}

.c-closing-x__bar:first-child {
  transform: translateY(0.9rem);
}

.c-closing-x__bar:last-child {
  transform: translateY(-0.9rem);
}

.c-closing-x__inner {
  display: block;
  width: 100%;
  height: 100%;
  background: white;
}

:first-child > .c-closing-x__inner {
  transform: rotate(45deg);
}

:last-child > .c-closing-x__inner {
  transform: rotate(-45deg);
}

.c-collaborators {
  align-items: center;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .c-collaborators {
    display: flex;
    justify-content: space-between;
  }
}

.c-collaborators__link {
  display: block;
  max-width: 15rem;
  padding: 1.5rem;
  margin: 2rem auto;
}

@media (min-width: 768px) {
  .c-collaborators__link {
    width: 20%;
    max-width: 15rem;
    padding-left: 0;
    padding-right: 0;
    margin: 2rem 0;
  }
}

.c-collaborators__logo {
  width: 100%;
}

@media (min-width: 1024px) {
  .c-comparison-table {
    display: flex;
  }
}

.c-comparison-table__inner {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .c-comparison-table__inner {
    flex: 1 0 auto;
    width: 33%;
  }
}

.c-comparison-table__inner + .c-comparison-table__inner {
  margin-top: 5rem;
}

@media (min-width: 1024px) {
  .c-comparison-table__inner + .c-comparison-table__inner {
    margin-top: 0;
  }
}

.c-comparison-table__inner td,
.c-comparison-table__inner th {
  height: 3.5rem;
}

@media (min-width: 1024px) {
  .c-comparison-table__inner td,
  .c-comparison-table__inner th {
    height: 5.5rem;
  }
}

@media (min-width: 1440px) {
  .c-comparison-table__inner td,
  .c-comparison-table__inner th {
    height: 7rem;
  }
}

.c-comparison-table__inner td:first-child,
.c-comparison-table__inner th:first-child {
  width: 12rem;
}

@media (min-width: 480px) {
  .c-comparison-table__inner td:first-child,
  .c-comparison-table__inner th:first-child {
    width: 24rem;
  }
}

@media (min-width: 1024px) {
  .c-comparison-table__inner td:first-child,
  .c-comparison-table__inner th:first-child {
    display: none;
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .c-comparison-table__inner:first-child {
    flex: 2 0 auto;
    width: 66%;
  }
  .c-comparison-table__inner:first-child td:first-child,
  .c-comparison-table__inner:first-child th:first-child {
    display: table-cell;
  }
}

.c-comparison-table__images td {
  background: transparent;
  border-color: transparent;
}

.c-comparison-table__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-comparison-table__image img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.c-comparison-table__sota {
  background: #68637b;
}

.theme-purple .c-comparison-table__sota {
  background: #68637b;
}

.c-exploration {
  margin: 0 0 6rem 1rem;
}

@media (min-width: 768px) {
  .c-exploration {
    margin-left: 0;
    margin-top: 4rem;
    display: flex;
  }
}

@media (min-width: 1440px) {
  .c-exploration {
    margin-top: 6rem;
  }
}

.c-exploration__item {
  margin-bottom: 4rem;
  padding: 0 2rem;
  border-left: 0.1rem solid #0e3da4;
}

.theme-purple .c-exploration__item {
  border-left-color: #614ab8;
}

@media (min-width: 768px) {
  .c-exploration__item {
    width: 33.33%;
  }
}

@media (min-width: 1440px) {
  .c-exploration__item {
    margin-left: 2rem;
  }
}

.c-figures {
  text-align: center;
}

@media (min-width: 1024px) {
  .c-figures {
    display: flex;
    justify-content: center;
  }
}

.c-figures--align-left {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.c-figures__item {
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .c-figures__item {
    margin: 3rem 3rem 0;
  }
}

@media (min-width: 1440px) {
  .c-figures__item {
    margin: 3rem 3rem 0;
  }
}

.c-figures--align-left .c-figures__item {
  margin: 3.5rem 0;
}

.c-figures--align-left .c-figures__item:nth-child(n+2) {
  margin-left: 3rem;
}

@media (min-width: 1024px) {
  .c-figures--align-left .c-figures__item:nth-child(n+2) {
    margin-left: 8rem;
  }
}

@media (min-width: 1024px) {
  .c-figures--align-left .c-figures__item:nth-child(n+2) {
    margin-left: 11rem;
  }
}

.c-figures__image {
  display: block;
  max-width: 13rem;
  max-height: 9rem;
  margin: auto;
}

.c-figures--align-left .c-figures__image {
  max-width: 7.4rem;
  max-height: none;
}

@media (min-width: 1440px) {
  .c-figures--align-left .c-figures__image {
    max-width: 10rem;
  }
}

.c-figures__caption {
  margin-top: 2rem;
  max-width: 15rem;
  font-size: 1.6rem;
  line-height: 1.2;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  font-size: 0.875rem;
  margin: 0;
  padding-top: 0;
  background-color: #0f3b9a;
}

@media (min-width: 1024px) {
  .c-footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 3rem 0;
  }
}

.theme-purple .c-footer {
  background-color: #4b3896;
}

.c-footer__spacer {
  flex-grow: 1;
}

.c-footer__homelink {
  position: relative;
  z-index: 1;
  padding: 1.5rem 3rem;
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  .c-footer__homelink {
    padding: 1rem 3rem 1.15rem;
    margin-bottom: 0;
    background: #0f3b9a;
  }
  .theme-purple .c-footer__homelink {
    background: #4b3896;
  }
}

.c-footer__logo {
  display: block;
  width: 11rem;
  max-width: none;
}

.c-footer__btn {
  position: absolute;
  right: 20px;
  top: 20px;
  margin: 0;
}

@media (min-width: 1024px) {
  .c-footer__btn {
    position: relative;
    top: auto;
    right: auto;
    margin-right: 3rem;
    align-self: center;
  }
}

.c-footer__links {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 20px;
  list-style-type: none;
  background: #0e3da4;
}

.c-footer__links:after {
  content: '';
  flex-grow: 1;
}

@media (min-width: 1024px) {
  .c-footer__links {
    display: none;
  }
}

.theme-purple .c-footer__links {
  background: #452aaa;
}

.c-footer__item {
  margin: 0;
  color: #fff;
}

.c-footer__itemlink {
  padding: 1rem;
  color: #b8ccf8;
  font-size: 1.4rem;
  text-decoration: none;
}

.c-footer__links-desktop {
  flex: 0 0 auto;
  margin: 0;
  list-style: none;
  margin-right: 3rem;
  align-items: center;
  display: none;
}

@media (min-width: 1024px) {
  .c-footer__links-desktop {
    display: flex;
  }
}

.c-footer__item-desktop {
  margin: 0;
}

.c-footer__itemlink-desktop {
  padding: 1rem;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.5rem;
  text-decoration: none;
}

/* stylelint-disable value-list-comma-space-after, indentation */
.c-hamburger {
  padding: 1.5rem 2rem;
}

@media (min-width: 1024px) {
  .c-hamburger {
    display: none;
  }
}

.c-hamburger__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2.8rem;
  height: 2rem;
  transform: translateZ(1px);
  cursor: pointer;
}

.c-hamburger__bar {
  display: block;
  width: 100%;
  height: 2px;
  transform-origin: center center;
  transition: transform 0.125s 0.125s, opacity 0s 0.125s;
}

.c-hamburger__inner {
  display: block;
  width: 100%;
  height: 100%;
  background: white;
  transition: transform 0.125s;
}

.has-menu-opened .c-hamburger__bar {
  transition: transform 0.125s, opacity 0s 0.125s;
}

.has-menu-opened .c-hamburger__bar:first-child {
  transform: translateY(0.9rem);
}

.has-menu-opened .c-hamburger__bar:last-child {
  transform: translateY(-0.9rem);
}

.has-menu-opened .c-hamburger__bar:not(:first-child):not(:last-child) {
  opacity: 0;
}

.has-menu-opened .c-hamburger__inner {
  transition: transform 0.125s 0.125s;
}

.has-menu-opened :first-child > .c-hamburger__inner {
  transform: rotate(45deg);
}

.has-menu-opened :last-child > .c-hamburger__inner {
  transform: rotate(-45deg);
}

/* stylelint-enable value-list-comma-space-after, indentation */
/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 50%;
  width: 100%;
  height: 5rem;
  padding: 0;
  max-width: 956px;
  transform: translateX(-50%);
}

@media (min-width: 1024px) {
  .c-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.8rem;
    margin: auto;
    background-color: transparent;
    transition: background-color 0.25s;
  }
}

@media (min-width: 1440px) {
  .c-header {
    height: 7.7rem;
    max-width: 1276px;
  }
}

@media (min-width: 1024px) {
  .has-menu-sticky .c-header,
  .always-menu-sticky .c-header {
    background-color: #0f3b9a;
  }
}

@media (min-width: 1024px) {
  .has-menu-sticky .theme-purple .c-header,
  .always-menu-sticky .theme-purple .c-header {
    background-color: #4b3896;
  }
}

.c-header__inner {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #0f3b9a;
}

.theme-purple .c-header__inner {
  background-color: #4b3896;
}

@media (min-width: 1024px) {
  .c-header__inner {
    width: auto;
    background-color: transparent;
  }
}

.c-header__homelink {
  display: block;
  position: relative;
  z-index: 1;
  padding: 1.5rem 2rem;
}

@media (min-width: 1024px) {
  .c-header__homelink {
    padding: 1.85rem 3rem;
    background: #0f3b9a;
  }
  .theme-purple .c-header__homelink {
    background-color: #4b3896;
  }
}

@media (min-width: 1440px) {
  .c-header__homelink {
    padding: 2.8rem 3rem;
  }
}

.c-header--diagnostics .c-header__homelink {
  padding: 1rem 2rem;
}

@media (min-width: 1024px) {
  .c-header--diagnostics .c-header__homelink {
    padding: 1.45rem 3rem;
  }
}

@media (min-width: 1440px) {
  .c-header--diagnostics .c-header__homelink {
    padding: 1.9rem 3rem;
  }
}

.c-header__logo {
  display: block;
  position: relative;
  z-index: 1;
  max-height: 2.1rem;
}

.c-header--diagnostics .c-header__logo {
  max-height: 2.9rem;
}

@media (min-width: 1440px) {
  .c-header--diagnostics .c-header__logo {
    max-height: 3.9rem;
  }
}

.c-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40rem;
  max-width: 956px;
  max-height: 100vh;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f0f0;
  background-position: center center;
  background-size: cover;
}

@media (min-width: 1024px) {
  .c-hero {
    height: 50.6rem;
  }
}

@media (min-width: 1440px) {
  .c-hero {
    height: 67.5rem;
    max-width: 1276px;
  }
}

.c-hero:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10rem;
  height: 1.2rem;
  background-color: #0f3b9a;
  transform: translateY(100%);
}

.theme-purple .c-hero:after {
  background-color: #614ab8;
}

@media (min-width: 1024px) {
  .c-hero:after {
    width: 14.7rem;
    height: 1.7rem;
  }
}

@media (min-width: 1440px) {
  .c-hero:after {
    width: 19.7rem;
    height: 2.3rem;
  }
}

.c-hero--purple-tint {
  background-color: rgba(75, 56, 150, 0.55);
}

.c-hero__container {
  position: relative;
  width: 100%;
}

@media (min-width: 1024px) {
  .c-hero__container {
    max-width: 796px;
  }
}

@media (min-width: 1440px) {
  .c-hero__container {
    max-width: 956px;
  }
}

.c-hero__h3 {
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}

@media (min-width: 1024px) {
  .c-hero__h3 {
    color: #0e3da4;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1440px) {
  .c-hero__h3 {
    max-width: 73rem;
    font-size: 3.4rem;
  }
}

.c-images {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;
}

@media (min-width: 480px) {
  .c-images {
    justify-content: flex-start;
  }
}

.c-images__image {
  max-width: 7.5rem;
}

@media (min-width: 1440px) {
  .c-images__image {
    max-width: 10rem;
  }
}

.c-images__image + .c-images__image {
  margin-left: 2rem;
}

@media (min-width: 480px) {
  .c-images__image + .c-images__image {
    margin-left: 4rem;
  }
}

.c-interlude {
  position: relative;
  width: calc(50% + 478px);
  height: 50vw;
  max-height: 31.5rem;
  background: url("../assets/images/bg-01.jpg") center right no-repeat;
  background-size: cover;
}

@media (min-width: 1440px) {
  .c-interlude {
    width: calc(50% + 638px);
    max-height: 42rem;
  }
}

.c-interlude__shape {
  position: absolute;
  left: 10%;
  right: 0;
  top: 35%;
  height: 65%;
  max-height: 15rem;
  background: rgba(14, 61, 164, 0.5);
}

@media (min-width: 1024px) {
  .c-interlude__shape {
    top: 12.8rem;
    left: 34%;
    right: 0;
  }
}

@media (min-width: 1440px) {
  .c-interlude__shape {
    top: 21rem;
    left: auto;
    right: 10%;
    width: 66.7rem;
  }
}

.c-legal-content em {
  font-style: italic;
}

.c-legal-content ol,
.c-legal-content ul {
  margin: 0;
  padding-left: 2em;
}

.c-legal-content ol ol,
.c-legal-content ol ul,
.c-legal-content ul ol,
.c-legal-content ul ul {
  margin: 1.5em 0;
}

.c-legal-content li {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: 0.21px;
  margin-bottom: 0;
}

@media (min-width: 1440px) {
  .c-legal-content li {
    font-size: 1.9rem;
  }
}

.c-legal-content li:not(:last-child) {
  margin-bottom: 1.5em;
}

.c-legal-content li li:not(:last-child) {
  margin-bottom: 1rem;
}

.c-legal-content strong {
  font-weight: 600;
}

.c-legal-content a {
  color: #4b3896;
  font-weight: 600;
  text-decoration: none;
}

.c-legal-content__contact {
  padding-top: 1em;
  padding-left: 2em;
}

@media (min-width: 1024px) {
  .c-legal-content__contact {
    padding-top: 3em;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.c-menu {
  width: 100%;
  margin: 0;
}

.has-menu-opened .c-menu {
  transform: translateY(0);
}

.c-menu--header {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5rem;
  padding: 1.35rem 0;
  background-color: #0c2f7c;
  transform: translateY(-100%);
  transition: transform 0.25s;
}

@media (min-width: 1024px) {
  .c-menu--header {
    display: flex;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    padding: 0;
    margin-right: 2rem;
    background-color: transparent;
    transform: none;
  }
}

.theme-purple .c-menu--header {
  background-color: #614ab8;
}

@media (min-width: 1024px) {
  .theme-purple .c-menu--header {
    background-color: transparent;
  }
}

.c-menu--footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.35rem 0;
}

@media (min-width: 1024px) {
  .c-menu--footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
}

.c-menu__item {
  margin: 0;
  list-style-type: none;
  flex-shrink: 1;
}

@media (min-width: 1024px) {
  .c-menu__item {
    display: block;
  }
}

.c-menu__link {
  display: inline-block;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.22;
  font-weight: normal;
  text-decoration: none;
}

@media (min-width: 1440px) {
  .c-menu__link {
    padding: 1rem 2rem;
  }
}

.c-menu--header .c-menu__link {
  padding: 0.85rem 20px 0.85rem 35px;
  opacity: 0;
  transition: all 0.25s;
}

.has-menu-opened .c-menu--header .c-menu__link {
  opacity: 1;
}

@media (min-width: 1024px) {
  .c-menu--header .c-menu__link {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .c-header--inverted .c-menu__link {
    color: #0e3da4;
  }
}

.has-menu-sticky .c-header--inverted .c-menu__link,
.always-menu-sticky .c-header--inverted .c-menu__link {
  color: #fff;
}

.c-menu--footer .c-menu__link {
  font-size: 1.8rem;
  padding: 0.5rem 3rem;
  letter-spacing: 0.57px;
}

@media (min-width: 1024px) {
  .c-menu--footer .c-menu__link {
    padding: 0.5rem 1rem;
  }
}

.has-menu-opened .c-menu__item:nth-last-child(1) .c-menu__link {
  transition-delay: 0.05s;
}

.has-menu-opened .c-menu__item:nth-last-child(2) .c-menu__link {
  transition-delay: 0.1s;
}

.has-menu-opened .c-menu__item:nth-last-child(3) .c-menu__link {
  transition-delay: 0.15s;
}

.has-menu-opened .c-menu__item:nth-last-child(4) .c-menu__link {
  transition-delay: 0.2s;
}

.has-menu-opened .c-menu__item:nth-last-child(5) .c-menu__link {
  transition-delay: 0.25s;
}

.has-menu-opened .c-menu__item:nth-last-child(6) .c-menu__link {
  transition-delay: 0.3s;
}

.has-menu-opened .c-menu__item:nth-last-child(7) .c-menu__link {
  transition-delay: 0.35s;
}

.has-menu-opened .c-menu__item:nth-last-child(8) .c-menu__link {
  transition-delay: 0.4s;
}

.has-menu-opened .c-menu__item:nth-last-child(9) .c-menu__link {
  transition-delay: 0.45s;
}

.has-menu-opened .c-menu__item:nth-last-child(10) .c-menu__link {
  transition-delay: 0.5s;
}

.c-menu__shortname {
  display: none;
}

@media (min-width: 1024px) {
  .c-menu__shortname {
    display: block;
  }
}

.c-menu__fullname {
  display: block;
}

@media (min-width: 1024px) {
  .c-menu__fullname {
    display: none;
  }
}

.c-menu--footer .c-menu__shortname {
  display: block;
}

.c-menu--footer .c-menu__fullname {
  display: none;
}

@media (min-width: 1024px) {
  .c-periodic-table__flex {
    display: flex;
  }
}

@media (min-width: 1024px) {
  .c-periodic-table__container--image {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 45%;
  }
}

@media (min-width: 1024px) {
  .c-periodic-table__container--text {
    display: flex;
    justify-content: flex-start;
    width: 55%;
  }
}

.c-periodic-table__image {
  display: block;
  margin: 0 auto;
  width: 21rem;
  height: 21rem;
}

@media (min-width: 1024px) {
  .c-periodic-table__image {
    width: 27rem;
    height: 27rem;
    margin-right: 7rem;
  }
}

@media (min-width: 1440px) {
  .c-periodic-table__image {
    width: 34rem;
    height: 30rem;
  }
}

.c-periodic-table__group {
  margin-top: 3rem;
}

@media (min-width: 1024px) {
  .c-periodic-table__group {
    display: flex;
    margin-top: 3rem;
  }
}

@media (min-width: 1024px) {
  .c-periodic-table__group {
    margin-top: 4rem;
  }
}

@media (min-width: 1024px) {
  .c-periodic-table__heading2 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .c-periodic-table__heading3 {
    text-align: center;
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1440px) {
  .c-periodic-table__heading3 {
    max-width: 88.5rem;
  }
}

.c-periodic-table__heading4 {
  font-size: 1.9rem;
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .c-periodic-table__heading4 {
    align-self: flex-start;
    min-width: 14rem;
    margin: 0 2rem 1rem 0;
    font-size: 1.6rem;
    text-align: right;
    line-height: 1;
  }
}

@media (min-width: 1440px) {
  .c-periodic-table__heading4 {
    font-size: 1.8rem;
  }
}

.c-periodic-table__paragraph {
  margin: 0;
  font-size: 1.6rem;
}

@media (min-width: 1024px) {
  .c-periodic-table__paragraph {
    max-width: 23.4rem;
    font-size: 1.6rem;
  }
}

@media (min-width: 1440px) {
  .c-periodic-table__paragraph {
    max-width: 29rem;
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .c-periodic-table--alt .c-periodic-table__image {
    max-width: 30rem;
  }
  .c-periodic-table--alt .c-periodic-table__flex {
    justify-content: center;
  }
  .c-periodic-table--alt .c-periodic-table__container {
    width: auto;
  }
  .c-periodic-table--alt .c-periodic-table__paragraph {
    max-width: 29.3rem;
  }
  .c-periodic-table--alt .c-periodic-table__heading4 {
    text-align: left;
  }
  .c-periodic-table--alt .c-periodic-table__group {
    display: block;
  }
}

.c-pipeline {
  --title-column-width: 213px;
  margin-top: 60px;
}

@media (min-width: 1024px) {
  .c-pipeline {
    margin-top: 114px;
    margin-inline: -80px;
  }
}

@media (min-width: 1440px) {
  .c-pipeline {
    margin-inline: 0;
  }
}

.c-pipeline__content {
  margin-inline: -20px;
}

@media (min-width: 1024px) {
  .c-pipeline__content {
    margin-inline: 0;
  }
}

.c-pipeline__header {
  display: none;
  justify-content: space-between;
  padding-left: var(--title-column-width);
  padding-bottom: 20px;
}

@media (min-width: 1024px) {
  .c-pipeline__header {
    display: flex;
  }
}

.c-pipeline__header-title {
  font-size: 2.1rem;
  font-weight: 500;
  color: #614ab8;
}

@media (min-width: 1280px) {
  .c-pipeline__header-title {
    font-size: 2.4rem;
  }
}

.c-pipeline__row {
  display: grid;
  column-gap: 30px;
  padding-block: 3.7rem 4rem;
  padding-inline: 2rem;
  background: var(--gradient, linear-gradient(270deg, #dadeea 0%, rgba(217, 217, 217, 0) 100%));
}

.c-pipeline__row + .c-pipeline__row {
  border-top: 0.5px solid #68637b;
}

.c-pipeline__row sup {
  top: -0.7em;
  font-size: 60%;
}

@media (min-width: 1024px) {
  .c-pipeline__row {
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: var(--title-column-width) auto 1fr;
    gap: 0;
    padding-inline: 0;
  }
}

.c-pipeline__row-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  grid-column: 1/-1;
}

@media (min-width: 1024px) {
  .c-pipeline__row-top {
    display: block;
    grid-column: unset;
    margin: 0;
  }
}

/* stylelint-disable selector-no-qualifying-type */
h3.c-pipeline__row-title {
  margin: 0;
  font-style: normal;
  font-size: 1.9rem;
  font-weight: 400;
  color: #68637b;
}

h3.c-pipeline__row-title b {
  display: block;
  font-size: 2.2rem;
  font-weight: 500;
  color: #4b3896;
}

/* stylelint-enable selector-no-qualifying-type */
.c-pipeline__row-toggle {
  display: flex;
  align-items: center;
  gap: 9px;
  border: 0;
  padding: 0;
  font-size: 1.9rem;
  font-family: inherit;
  font-weight: 500;
  color: #4b3896;
  background: #6bf0fc;
  cursor: pointer;
}

.c-pipeline__row-toggle .is-open {
  display: none;
}

.has-pipeline-opened .c-pipeline__row-toggle .is-closed,
.has-pipeline-opened .c-pipeline__row-toggle .vert {
  display: none;
}

.has-pipeline-opened .c-pipeline__row-toggle .is-open {
  display: block;
}

@media (min-width: 1024px) {
  .c-pipeline__row-toggle {
    margin-top: 2rem;
  }
}

.c-pipeline__row-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  grid-column: 1/-1;
}

@media (min-width: 1024px) {
  .c-pipeline__row-header {
    display: none;
  }
}

.c-pipeline__row-header-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #614ab8;
}

@media (min-width: 480px) {
  .c-pipeline__row-header-title {
    flex: 1;
  }
}

.c-pipeline__row-arrow {
  display: grid;
  align-items: center;
  grid-column: 1;
  grid-row: 3;
}

.c-pipeline__row-arrow::before {
  content: "";
  display: block;
  height: 30px;
  width: 18%;
  background: url("../assets/images/box-arrow.svg") center right no-repeat;
  background-size: cover;
}

@media (min-width: 480px) {
  .c-pipeline__row-arrow::before {
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .c-pipeline__row-arrow {
    position: relative;
    z-index: 1;
    min-height: 80px;
    min-width: 164px;
    padding: 1.6rem 2.4rem 1.6rem 1.6rem;
    grid-column: unset;
    grid-row: unset;
  }
  .c-pipeline__row-arrow::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

@media (min-width: 1280px) {
  .c-pipeline__row-arrow {
    min-width: 150px;
  }
}

.c-pipeline__row-arrow--wide:before {
  width: 38%;
}

@media (min-width: 480px) {
  .c-pipeline__row-arrow--wide::before {
    width: 36%;
  }
}

@media (min-width: 1024px) {
  .c-pipeline__row-arrow--wide {
    min-width: 270px;
  }
  .c-pipeline__row-arrow--wide::before {
    width: 100%;
  }
}

.c-pipeline__row-arrow-title {
  font-size: 1.9rem;
  line-height: 1.26;
  margin-top: 8px;
}

.c-pipeline__row-arrow-title br {
  display: none;
}

@media (min-width: 1024px) {
  .c-pipeline__row-arrow-title {
    margin: 0;
    color: #fff;
  }
  .c-pipeline__row-arrow-title br {
    display: block;
  }
}

.c-pipeline__row-note {
  grid-column: 1;
  grid-row: 3;
  font-style: italic;
  padding-left: 2.6rem;
  margin-top: 3px;
  margin-left: 18%;
}

@media (min-width: 480px) {
  .c-pipeline__row-note {
    margin-left: 25%;
  }
}

@media (min-width: 1024px) {
  .c-pipeline__row-note {
    position: static;
    grid-row: unset;
    grid-column: unset;
    margin-left: 0;
    margin-top: 0;
  }
}

.c-pipeline__row-more {
  display: none;
  grid-row: 2;
  grid-column: 1/-1;
  padding-bottom: 2.9rem;
}

.has-pipeline-opened .c-pipeline__row-more {
  display: block;
}

@media (min-width: 1024px) {
  .c-pipeline__row-more {
    padding-top: 3rem;
    padding-bottom: 0;
    grid-row: 2/3;
    grid-column: 1/4;
    padding-right: 10.2rem;
  }
}

@media (min-width: 1024px) {
  .c-section-about__flex {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.c-section-about__graphic {
  max-width: 10.8rem;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: -3rem;
  transform: translateX(50%) translateY(50%);
}

@media (min-width: 1024px) {
  .c-section-about__graphic {
    left: -8rem;
    top: 0;
    right: auto;
    bottom: auto;
    flex-shrink: 0;
    width: 9.89rem;
    height: 13.3rem;
    transform: none;
  }
}

@media (min-width: 1440px) {
  .c-section-about__graphic {
    left: -12rem;
  }
}

.c-section-about__image {
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 1024px) {
  .c-section-about__text {
    max-width: 58.5rem;
  }
}

@media (min-width: 1440px) {
  .c-section-about__text {
    max-width: 78rem;
  }
}

.c-section-about__text *:first-child {
  margin-top: 0;
}

.c-simple {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .c-simple {
    flex-direction: row;
  }
}

.c-simple__image {
  align-self: center;
  width: 20rem;
  margin-top: 2rem;
}

@media (min-width: 1024px) {
  .c-simple__image {
    align-self: flex-start;
    margin-top: 0;
    margin-right: 6.6rem;
  }
}

@media (min-width: 1440px) {
  .c-simple__image {
    width: 27rem;
    flex-shrink: 0;
  }
}

@media (min-width: 1024px) {
  .c-simple__heading {
    margin-top: 0;
  }
}

.c-statistics__element {
  display: flex;
  padding: 2rem 0;
  align-items: center;
}

.c-statistics__val {
  position: relative;
  width: 5rem;
  color: #0e3da4;
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}

.c-statistics__val:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 5rem;
  height: 5rem;
  border: 1px solid #68637b;
  border-radius: 2.5rem;
  transform: translateX(-50%) translateY(-50%);
}

.c-statistics__val--double:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 4.2rem;
  height: 4.2rem;
  border: 1px solid #68637b;
  border-radius: 2.1rem;
  transform: translateX(-50%) translateY(-50%);
}

.c-statistics__attr {
  margin-left: 2.5rem;
  color: #0e3da4;
  font-size: 1.8rem;
  font-weight: 500;
  font-style: italic;
}

/* ==========================================================================
   #STYLEGUIDE
   ========================================================================== */
.c-style-guide__intro {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.c-style-guide__desc {
  color: #666;
  margin: 0;
}

.c-style-guide__section {
  padding: 1rem 0;
}

.c-style-guide__section:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-style-guide__section-title {
  border-bottom: 1px solid #ccc;
  clear: both;
  color: #666;
  font-size: 1.25rem;
  font-weight: normal;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  width: 50%;
}

.c-style-guide__section-subtitle {
  border-left: 6px solid #ccc;
  clear: both;
  color: #999;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 2em 0;
  padding-left: 1em;
  text-transform: uppercase;
}

.c-symbol--abstract-1 {
  display: none;
}

@media (min-width: 1024px) {
  .c-symbol--abstract-1 {
    display: block;
    position: absolute;
    width: 15rem;
    right: 5%;
    bottom: 0;
    transform: translateY(60%) rotate(-55deg);
  }
}

.c-symbol--swan {
  display: none;
  position: absolute;
}

@media (min-width: 1024px) {
  .c-symbol--swan {
    display: block;
    top: 50%;
    right: -5rem;
    width: 16rem;
    transform: translateY(-50%);
  }
}

@media (min-width: 1440px) {
  .c-symbol--swan {
    width: 25rem;
    right: -15rem;
    transform: translateY(-50%);
  }
}

.c-symbol--abstract-3 {
  display: block;
  margin: 0 auto;
  width: 10rem;
}

@media (min-width: 1024px) {
  .c-symbol--abstract-3 {
    position: absolute;
    top: 6.5rem;
    right: 10%;
    width: 14rem;
  }
}

@media (min-width: 1440px) {
  .c-symbol--abstract-3 {
    width: 18.5rem;
  }
}

.c-symbol--abstract-4 {
  display: block;
  margin: 0 auto 5rem;
  width: 20rem;
}

@media (min-width: 1024px) {
  .c-symbol--abstract-4 {
    position: absolute;
    top: 18rem;
    right: 4rem;
  }
}

@media (min-width: 1440px) {
  .c-symbol--abstract-4 {
    top: 24rem;
    right: 7rem;
    width: 31.7rem;
  }
}

.c-team {
  margin: 0;
  list-style-type: none;
}

@media (min-width: 1024px) {
  .c-team {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-team__card {
  position: relative;
  display: block;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .c-team__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.3%;
    margin-top: 5rem;
    text-align: left;
  }
}

.c-team__card + .c-team__card {
  margin-top: 2.5rem;
}

@media (min-width: 1024px) {
  .c-team__card + .c-team__card {
    margin-top: 5rem;
  }
}

.c-team__bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.4rem;
  height: 16.4rem;
  border-radius: 99rem;
  margin: 0 auto;
  background-color: white;
}

@media (min-width: 1024px) {
  .c-team__bg {
    width: 14rem;
    height: 14rem;
  }
}

@media (min-width: 1440px) {
  .c-team__bg {
    width: 18.6rem;
    height: 18.6rem;
  }
}

.c-team__info {
  display: inline-block;
}

@media (min-width: 1024px) {
  .c-team__info {
    width: 13rem;
  }
}

@media (min-width: 1440px) {
  .c-team__info {
    width: 17.6rem;
  }
}

.c-team__image {
  max-width: 95%;
  max-height: 95%;
  margin-top: 2rem;
}

.c-team__name {
  position: relative;
  z-index: 2;
  margin-top: 1.2rem;
  color: #0e3da4;
  font-size: 1.8rem;
  font-weight: 500;
  display: inline-block;
}

@media (min-width: 1024px) {
  .c-team__name {
    font-size: 2rem;
    white-space: nowrap;
  }
}

@media (min-width: 1440px) {
  .c-team__name {
    font-size: 2.4rem;
  }
}

.theme-purple .c-team__name {
  color: #614ab8;
}

.c-team__name:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 28%;
  bottom: 28%;
  background: #6bf0fc;
  opacity: 0;
  transition: opacity 0.25s;
}

.c-team__name > * {
  position: relative;
  z-index: 1;
}

.c-team__familyname:after {
  content: ",";
}

.c-team__familyname--no-degree:after {
  content: "";
}

.c-team__card:hover .c-team__name:before {
  opacity: 1;
}

.c-team__role {
  color: #0e3da4;
  font-size: 1.6rem;
  font-weight: normal;
}

@media (min-width: 1024px) {
  .c-team__role {
    font-size: 1.8rem;
    white-space: nowrap;
  }
}

@media (min-width: 1440px) {
  .c-team__role {
    font-size: 2rem;
  }
}

.theme-purple .c-team__role {
  color: #614ab8;
}

.c-team__org {
  font-size: 1.4rem;
  color: #75747a;
}

@media (min-width: 1024px) {
  .c-team__org {
    font-size: 1.6rem;
    white-space: nowrap;
  }
}

@media (min-width: 1440px) {
  .c-team__org {
    font-size: 1.8rem;
  }
}

.c-team__details {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.25s;
  transform: scale(0);
  transform-origin: center 25%;
  pointer-events: none;
  cursor: default;
}

@media (max-width: 1023px) {
  .c-team__details {
    top: 0 !important;
    left: 0 !important;
  }
}

@media (min-width: 1024px) {
  .c-team__details {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 796px;
    height: auto;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 1440px) {
  .c-team__details {
    width: 1116px;
  }
}

.has-modal-opened .c-team__details {
  transform: scale(1);
  pointer-events: all;
}

@media (min-width: 1024px) {
  .has-modal-opened .c-team__details {
    display: block;
    transform: translateX(-50%) translateY(-50%);
  }
}

.c-team__details-header {
  padding: 20px;
  color: #fff;
  background-color: #0e3da4;
}

@media (min-width: 1024px) {
  .c-team__details-header {
    padding: 3rem 8rem;
  }
}

.theme-purple .c-team__details-header {
  background-color: #614ab8;
}

.c-team__details-name {
  color: inherit;
  margin: 0;
  font-size: 1.8rem;
}

.theme-purple .c-team__details-name {
  color: #fff;
}

@media (min-width: 1024px) {
  .c-team__details-name {
    font-size: 4rem;
  }
}

.c-team__details-role {
  font-size: 1.6rem;
}

@media (min-width: 1024px) {
  .c-team__details-role {
    font-size: 2.8rem;
    font-style: italic;
  }
}

.c-team__details-org {
  font-size: 1.4rem;
}

@media (min-width: 1024px) {
  .c-team__details-org {
    display: none;
  }
}

.c-team__details-content {
  flex-grow: 1;
  background-color: #fff;
}

@media (min-width: 1024px) {
  .c-team__details-content {
    padding: 2rem 8rem;
  }
}

.c-team__details-note {
  margin: 20px;
  text-align: left;
}

@media (min-width: 1024px) {
  .c-team__details-note {
    margin: 20px 0;
  }
}

.c-tiles {
  position: relative;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .c-tiles {
    max-width: 65rem;
  }
}

@media (min-width: 1440px) {
  .c-tiles {
    max-width: 85rem;
  }
}

.c-tiles__title {
  text-align: center;
  margin: 3rem auto;
  max-width: 24rem;
  line-height: 1.25;
}

@media (min-width: 1024px) {
  .c-tiles__title {
    max-width: none;
    height: 2.6rem;
    color: #614ab8;
    border: 1px solid #614ab8;
    border-bottom: 0;
    margin: 0 0 2rem 0;
  }
}

@media (min-width: 1024px) {
  .c-tiles__title-text {
    display: inline-block;
    background-color: #f0f0f0;
    padding-left: 2rem;
    padding-right: 2rem;
    transform: translateY(-50%);
  }
}

.c-tiles__legend {
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
}

@media (min-width: 1024px) {
  .c-tiles__legend {
    display: none;
  }
}

@media (min-width: 1440px) {
  .c-tiles__legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 5.2rem;
    bottom: 0;
    width: 20rem;
    margin-top: 0;
    transform: translateX(-100%);
  }
}

.c-tiles__legend-item {
  align-items: center;
  max-width: 15rem;
  color: #614ab8;
  font-size: 1.4rem;
  line-height: 1.2;
  text-align: center;
}

@media (min-width: 1440px) {
  .c-tiles__legend-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 50%;
  }
}

@media (min-width: 1440px) {
  .c-tiles__legend-item:first-child {
    margin-bottom: 1.8rem;
  }
}

@media (min-width: 1440px) {
  .c-tiles__legend-item:last-child {
    margin-top: 1.8rem;
    padding: 2.5rem 0;
  }
}

@media (min-width: 1440px) {
  .c-tiles__legend-text {
    display: block;
    margin-top: 1rem;
    font-size: 1.6rem;
  }
}

.c-tiles__legend-symbol {
  display: none;
}

@media (min-width: 1440px) {
  .c-tiles__legend-symbol {
    display: block;
    width: 9rem;
  }
}

.c-tiles__content {
  font-size: 0;
}

@media (min-width: 1024px) {
  .c-tiles__content {
    display: flex;
  }
}

.c-tiles__group {
  display: flex;
}

@media (min-width: 1024px) {
  .c-tiles__group {
    flex-direction: column;
    width: 20%;
    margin-top: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.c-tiles__group + .c-tiles__group {
  margin-top: 2rem;
}

@media (min-width: 1024px) {
  .c-tiles__group + .c-tiles__group {
    margin-top: 0;
  }
}

.c-tiles__tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 50%;
  padding: 2rem;
  border: 1px solid #614ab8;
  line-height: 1;
}

@media (min-width: 1024px) {
  .c-tiles__tile {
    width: 100%;
    height: 16rem;
    border-top-color: transparent;
    border-right-width: 0;
    padding: 2.5rem 1rem;
  }
  .c-tiles__tile:first-child {
    padding-top: 0;
    margin-bottom: 1.3rem;
  }
  .c-tiles__tile:last-child {
    margin-top: 1.3rem;
  }
  .c-tiles__tile:last-child:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 10%;
    width: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
}

@media (min-width: 1440px) {
  .c-tiles__tile {
    height: 19rem;
  }
  .c-tiles__tile:first-child {
    margin-bottom: 1.8rem;
  }
  .c-tiles__tile:last-child {
    margin-top: 1.8rem;
  }
}

@media (min-width: 1024px) {
  :first-child > .c-tiles__tile:last-child:after {
    display: none;
  }
}

@media (min-width: 1024px) {
  :last-child > .c-tiles__tile {
    border-right-width: 1px;
  }
}

.c-tiles__tile--fill {
  background-color: #614ab8;
}

.c-tiles__symbol {
  width: 7.7rem;
  height: 7.7rem;
  flex-shrink: 0;
  max-width: none;
}

@media (min-width: 1440px) {
  .c-tiles__symbol {
    width: 9rem;
    height: 9rem;
  }
}

.c-tiles__label {
  margin-top: 1rem;
  color: #614ab8;
  text-align: center;
  font-size: 1.2rem;
}

@media (min-width: 1440px) {
  .c-tiles__label {
    font-size: 1.6rem;
  }
}

.c-tiles__tile--fill .c-tiles__label {
  color: #fff;
}

.c-tiles__heading {
  margin: 3rem 0;
  color: #4b3896;
  font-size: 1.9rem;
  text-align: center;
}

@media (min-width: 1024px) {
  .c-tiles__heading {
    position: absolute;
    width: 100%;
    height: 2.6rem;
    top: calc(50% + 2.3rem);
    left: 50%;
    border-left: 1px solid #614ab8;
    border-right: 1px solid #614ab8;
    margin: 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.6rem;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    transform: translateX(-50%) translateY(-50%);
    overflow: hidden;
  }
}

@media (min-width: 1440px) {
  .c-tiles__heading {
    height: 3.6rem;
    line-height: 3.6rem;
  }
}

.c-vimeo {
  display: flex;
  justify-content: center;
}

.c-vimeo__iframe {
  width: 100%;
  max-width: 100%;
  height: 58vw;
  max-height: 36rem;
  margin: auto;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

.u-center {
  text-align: center;
}

.u-center-children > * {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.u-flexcenter {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.u-left {
  text-align: left;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-color {
  color: #68637b;
}

.u-color--primary {
  color: #0e3da4;
}

.theme-purple .u-color--primary {
  color: #4b3896;
}

.u-color--secondary {
  color: #6bf0fc;
}

.u-fill {
  background: #f0f0f0;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

@media (min-width: 1024px) {
  .u-long--large {
    max-width: 60rem;
  }
}

@media (min-width: 1440px) {
  .u-long--large {
    max-width: 75rem;
  }
}

.u-no-vmargin,
.theme-purple .u-no-vmargin {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .u-margin {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}

@media (min-width: 1024px) {
  .u-demargin {
    margin-left: -8rem;
    margin-right: -8rem;
  }
}

.u-vmargin {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.u-vmargin--big {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

@media (min-width: 1024px) {
  .u-medium--large {
    max-width: 51rem;
  }
}

@media (min-width: 1440px) {
  .u-medium--large {
    max-width: 60rem;
  }
}

.u-no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.u-vpadding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  .u-vpadding--medium {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.u-vpadding-2 {
  padding-top: 5rem;
  padding-bottom: 8rem;
}

.u-vpadding-connector {
  padding-top: 3rem;
}

@media (min-width: 1024px) {
  .u-vpadding-connector {
    padding-top: 1rem;
  }
}

@media (min-width: 1440px) {
  .u-vpadding-connector {
    padding-top: 1px;
  }
}

.u-vpadding--vimeo {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.u-vpadding--big {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media (min-width: 1024px) {
  .u-vpadding--big {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 1440px) {
  .u-vpadding--big {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

.u-vpadding--bigger {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

@media (min-width: 1024px) {
  .u-vpadding--bigger {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

@media (min-width: 1440px) {
  .u-vpadding--bigger {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}

.u-vpadding--biggest {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

@media (min-width: 1024px) {
  .u-vpadding--biggest {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
}

@media (min-width: 1440px) {
  .u-vpadding--biggest {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
}

.u-relative {
  position: relative;
}

.u-veryshort {
  max-width: 28rem;
}

@media (min-width: 1024px) {
  .u-veryshort--large {
    max-width: 28rem;
  }
}

@media (min-width: 1024px) {
  .u-veryshort--full {
    max-width: 37rem;
  }
}

@media (min-width: 1024px) {
  .u-short--large {
    max-width: 45rem;
  }
}

@media (min-width: 1024px) {
  .u-short--xlarge {
    max-width: 65rem;
  }
}

@media (min-width: 1440px) {
  .u-short--full {
    max-width: 56rem;
  }
}

@media (min-width: 1440px) {
  .u-short--xfull {
    max-width: 78rem;
  }
}

.u-short--indent {
  padding-left: 8rem;
}

.u-style-normal {
  font-style: normal;
}

#__bs_notify__ {
  /* stylelint-disable-line selector-max-id */
  display: none !important;
}
